import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"mb-4",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Treasure X Change Logs ")])],1)],1),_c(VCol,{attrs:{"cols":"12"}},_vm._l((_vm.changeLogs),function(log){return _c(VCard,{key:log.id,staticClass:"mb-1"},[_c(VCardTitle,[_vm._v(" "+_vm._s(log.version)+" ("+_vm._s(log.is_released ? 'Released' : 'Upcoming Release')+") "),_c(VSpacer),_c('div',[_vm._v(_vm._s(log.release_date))])],1),_c(VCardText,[_c(VRow,[_c(VList,_vm._l((log.change_logs),function(change,index){return _c(VListItem,{key:index},[_c(VListItemContent,[_c(VListItemTitle,[_c(VChip,{staticClass:"mb-2",attrs:{"color":_vm.getChipColor(change.change_type)}},[_vm._v(" "+_vm._s(change.change_type)+" ")])],1),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(change.description)+" ")])],1)],1)}),1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }