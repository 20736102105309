<template>
  <v-row
    justify="center"
    class="mb-4"
  >
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Treasure X Change Logs
        </v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card v-for="log in changeLogs" :key="log.id" class="mb-1">
        <v-card-title>
          {{ log.version }} ({{ log.is_released ? 'Released' : 'Upcoming Release' }})
          <v-spacer></v-spacer>
          <div>{{ log.release_date }}</div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-list>
              <v-list-item v-for="(change, index) in log.change_logs" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip class="mb-2" :color="getChipColor(change.change_type)">
                      {{ change.change_type }}
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ change.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      changeLogs: [],
    }
  },
  mounted() {
    this.getChangeLogs()
  },
  methods: {
    async getChangeLogs() {
      try {
        this.$store.dispatch('encounters/syncingModal', true)
        const response = await this.$store.dispatch('changeLogs/getChangeLogs')
        this.changeLogs = response
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('encounters/syncingModal', false)
      }
    },
    getChipColor(changeType) {
      switch (changeType) {
        case 'Improvement':
          return 'success';
        case 'Fix':
          return 'warning';
        case 'New':
          return 'primary';
        default:
          return 'default';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
// .v-list-item__content {
//   padding: 12px 5px;
// }
</style>
